import { makeVar, useReactiveVar } from "@apollo/client";
import { parseCookies } from "nookies";
import { useEffect, useState } from "react";

export enum FileType {
  "Qr" = "Qr",
  "QrLogo" = "QrLogo",
  "Avatar" = "Avatar",
  "LinkThumbnail" = "LinkThumbnail",
  "AccountAvatar" = "AccountAvatar",
  "PageBg" = "PageBg",
  "InstaGridImage" = "InstaGridImage",
  "QrSlLogo" = "QrSlLogo",
  "Favicon" = "Favicon",
}

type UploadFile = {
  file: File | Blob;
  itemId: string;
  type: FileType;
};

type CopyFile = {
  url: string;
  itemId: string;
  type: FileType;
};

const uploadPendingRequests = makeVar<{
  pending: { id: string; type: FileType }[];
  done: { id: string; type: FileType; url: string }[];
}>({
  pending: [],
  done: [],
});

export const uploadFile = (image: UploadFile) => {
  const { done, pending } = uploadPendingRequests();

  uploadFiles([image]).then((data) => {
    const { done: _done, pending: _pending } = uploadPendingRequests();

    const { url, fileType, itemId } = data[0];

    const d = [
      ..._done,
      {
        url: url,
        type: fileType,
        id: image.itemId || "",
      },
    ];

    const p = _pending.filter((p) => p.id !== image.itemId);
  });
};

export const copyExternalFile = (image: CopyFile) => {
  const { done, pending } = uploadPendingRequests();

  uploadPendingRequests({
    done: [...done],
    pending: [
      ...pending,
      {
        type: image.type,
        id: image.itemId || "",
      },
    ],
  });

  copyFile(image).then((data) => {
    const { done: _done, pending: _pending } = uploadPendingRequests();

    const { url, fileType, itemId } = data;

    const d = [
      ..._done,
      {
        url: url,
        type: fileType,
        id: image.itemId,
      },
    ];

    const p = _pending.filter((p) => p.id !== image.itemId);

    setTimeout(
      () =>
        uploadPendingRequests({
          done: d,
          pending: p,
        }),
      100
    );
  });
};

export const removeFromDone = (id: string) => {
  const { done, pending } = uploadPendingRequests();
  const withoutremoved = done.filter((d) => d.id !== id);
  uploadPendingRequests({
    pending: pending,
    done: withoutremoved,
  });
};

export const usePendingRequests = ({ cb }: { cb: any }) => {
  const requests = useReactiveVar(uploadPendingRequests);

  const [isRequestedUserAction, setRequestedUserAction] = useState(false);

  useEffect(() => {
    if (isRequestedUserAction && requests.pending.length <= 0) {
      setRequestedUserAction(false);
      submit();
    }
  }, [requests, isRequestedUserAction]);

  const requestUserAction = (v: boolean) => {
    setRequestedUserAction(v);
  };

  const getDoneItem = (id: string) => {
    return requests.done.find((d) => d.id === id);
  };

  const submit = () => {
    if (requests.pending.length > 0 && !isRequestedUserAction) {
      requestUserAction(true);
      return;
    }

    cb();
  };

  return {
    submit,
    isRequested: isRequestedUserAction,
    getDoneItem,
    removeFromDone,
  };
};

const useUploader = () => {
  const [uploading, setUploading] = useState(false);
  const [err, setErr] = useState(false);

  const upload = async function (image: UploadFile[], progress?: () => void) {
    setUploading(true);
    const data = new FormData();

    image.forEach((i, k) => data.append(i.type, i.file, i.itemId));

    const { joytoken } = parseCookies();
    try {
      const response = await fetch(
        process.env.NEXT_PUBLIC_URL + "file/upload",
        {
          method: "POST",
          credentials: "include",
          headers: {
            //   "Content-Type": "multipart/form-data",
            authorization: joytoken ? `Bearer ${joytoken}` : "",
          },
          body: data,
        }
      );
      const _data = await response.json();

      if (_data?.statusCode >= 400) {
        setErr(true);
        setUploading(false);
        throw new Error(_data.message);
      }

      setUploading(false);
      return _data;
    } catch (error) {
      setUploading(false);
    }
  };

  return {
    upload,
    err,
    uploading,
  };
};

export const uploadFiles = async function (
  image: UploadFile[],
  progress?: () => void
) {
  const data = new FormData();

  image.forEach((i, k) => data.append(i.type, i.file, i.itemId));

  const { joytoken } = parseCookies();
  try {
    const response = await fetch(process.env.NEXT_PUBLIC_URL + "file/upload", {
      method: "POST",
      credentials: "include",
      headers: {
        //   "Content-Type": "multipart/form-data",
        authorization: joytoken ? `Bearer ${joytoken}` : "",
      },
      body: data,
    });
    const _data = await response.json();

    if (_data?.statusCode >= 400) {
      throw new Error(_data.message);
    }

    return _data;
  } catch (error) {
    console.log(error);
  }
};

export const copyFile = async (file: CopyFile) => {
  const { joytoken } = parseCookies();

  try {
    const response = await fetch(process.env.NEXT_PUBLIC_URL + "file/copy", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        authorization: joytoken ? `Bearer ${joytoken}` : "",
      },
      body: JSON.stringify({
        file,
      }),
    });

    const _data = await response.json();

    if (_data?.statusCode >= 400) {
      throw new Error(_data.message);
    }

    return _data;
  } catch (error) {}
};

export default useUploader;
