import { SubscriptionPlan, useMeQuery } from "../../../../generated/graphql";

const PermisionWeight = {
  [SubscriptionPlan.Free]: 0,
  [SubscriptionPlan.Plus]: 1,
  [SubscriptionPlan.Pro]: 2,
  [SubscriptionPlan.Enterprise]: 3,
};

const useSubscriptionPlan = () => {
  const { data } = useMeQuery({ fetchPolicy: "cache-only" });

  let permision = 0;

  if (data?.me.subscriptionPlan.name === SubscriptionPlan.Plus) {
    permision = 1;
  } else if (data?.me.subscriptionPlan.name === SubscriptionPlan.Pro) {
    permision = 2;
  } else if (data?.me.subscriptionPlan.name === SubscriptionPlan.Enterprise) {
    permision = 3;
  }

  const checkPermision = (required: SubscriptionPlan) => {
    return permision >= PermisionWeight[required];
  };

  return {
    checkPermision: checkPermision,
    plan: data?.me.subscriptionPlan.name,
    pid: data?.me.subscriptionPlan.price_id,
    isActive: data?.me.active,
    isLogged: !!data?.me,
  };
};

export default useSubscriptionPlan;
