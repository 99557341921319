import { makeVar, useReactiveVar } from "@apollo/client";
import {
  AppearanceModel,
  PageSocialIconsModel,
  useMePageQuery,
  useMeQuery,
} from "../../../../../generated/graphql";
import * as themes from "../customization/themes";
import * as filters from "../themes/filters";
import * as buttons from "../themes/buttons";
import client from "../../../../../config/apollo";
import { useMemo } from "react";

export type Filters = keyof typeof filters;
export type Themes = keyof typeof themes;
export type Buttons = keyof typeof buttons;

export const FONT_LIST = [
  `Open Sans, sans-serif`,
  `Courgette, cursive`,
  `Oswald, sans-serif`,
  `Goblin One, cursive`,
  `Girassol, cursive`,
  `Playfair Display, serif`,
  `Open Sans Condensed, sans-serif`,
  `Reggae One, cursive`,
  `Righteous, cursive`,
  `Pangolin, cursive`,
  `Chela One, cursive`,
  `Lobster, cursive`,
  `EB Garamond, serif`,
  `Pacifico, cursive`,
  `Architects Daughter, cursive`,
  `Balsamiq Sans, cursive`,
  `Montserrat, sans-serif`,
  `Montserrat Alternates, sans-serif`,
  `Mukta, sans-serif`,
  `Indie Flower, cursive`,
];

export const DEFAULT_SOCIAL_STATE: PageSocialIconsModel = {
  icons: [],
  iconsColor: "#000000",
  buttonIconsColor: "#000000",
};

export const useAppearance = () => {
  const { data } = useMeQuery({
    fetchPolicy: "cache-only",
  });
  return data?.me.page.appearance || DEFAULT_APPEARANCE;
};

export const useAppearanceLocalUpdate = () => {
  const { data } = useMeQuery({
    fetchPolicy: "cache-only",
  });
  return {
    update: useMemo(
      () =>
        ({ key, value }: { key: string; value: any }) => {
          client.cache.modify({
            id: `PageModel:${data?.me.page.id}`,
            fields: {
              appearance(c) {
                return {
                  ...c,
                  [key]: value,
                };
              },
            },
          });
        },
      []
    ),
    state: data?.me.page.appearance || DEFAULT_APPEARANCE,
  };
};

export const DEFAULT_APPEARANCE: AppearanceModel = {
  profileImage: null,
  social: DEFAULT_SOCIAL_STATE,
} as AppearanceModel;
