import { useEffect, useState } from "react";

const copy = (val: any, cb?: any) => {
  const el = document.createElement("textarea");
  el.value = val;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  cb && cb();
};

const useCopy = () => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      const t = setTimeout(() => {
        setCopied(false);
      }, 500);
      return () => clearTimeout(t);
    }
  }, [copied]);

  const copy = async (val: any) => {
    try {
      await navigator.clipboard.writeText(val);
      setCopied(true);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };
  return { copy, copied };
};

export const inputValueLength = (val: string) => {
  const el = document.createElement("div");
  el.textContent = val;
  el.style.position = "absolute";
  el.style.left = "-9999px";
  el.style.fontSize = "16px";
  document.body.appendChild(el);
  const w = el.clientWidth + 46;
  document.body.removeChild(el);
  return w;
};

export default useCopy;
