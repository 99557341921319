import { ColorType, TGradientColor } from "./types";

export const renderGradientDown = (color: string, secondColor: string) => {
  return `background-color: ${secondColor};
    background: linear-gradient(180deg, ${secondColor} 30%, ${color} 100%);
  `;
};

export const renderGradientUp = (color: string, secondColor: string) => {
  return `background-color: ${color};
    background: linear-gradient(180deg, ${color} 30%, ${secondColor} 100%);
  `;
};

export const radialGradient = (color: string, secondColor: string) => {
  return `background: radial-gradient(180deg, ${color} 30%, ${secondColor} 100%);`;
};

export const renderFlat = (color: string, secondColor: string) => {
  return `background-color: ${color};`;
};

const BG_TYPES = {
  flat: renderFlat,
  Flat: renderFlat,
  LinearGradient: renderGradientUp,
  RadialGradient: radialGradient,
  gradientUp: renderGradientUp,
  gradientDown: renderGradientDown,
};

export const renderGradientColor = ({
  type,
  firstColor,
  secondColor,
}: TGradientColor) => {
  return BG_TYPES[type](firstColor, secondColor);
};
