import { FONT_LIST } from "../components/page/dashboard/page-generator/state";

export const createFontsUrl = (customFont: any) => {
  let fonts = [...FONT_LIST, "Poppins"];
  if (customFont?.id === -1) {
    fonts.push(customFont.family);
  }
  return fonts
    .map((f) => "family=" + f.split(",")[0].replace(" ", "+"))
    .join("&");
};
