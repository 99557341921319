import React, { useState } from "react";

interface Props {
  defaultInterval?: DateInterval;
  defaultDate?: string;
}

export interface DateInterval {
  from: string | null;
  to: string | null;
}

export const splitInterval = (i: string): DateInterval => {
  if (i === "")
    return {
      from: "1/1/1970",
      to: new Date().toDateString(),
    };
  const [from, to] = i.split("to");
  return {
    from,
    to,
  };
};

export const getDateInterval = (day: number = 30) => {
  const today = new Date();
  const lastXDays = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - day
  );

  return {
    to: today.toLocaleDateString("EN"),
    from: lastXDays.toLocaleDateString("EN"),
  };
};

const useDate = (P: Props) => {
  const { from, to } = P?.defaultInterval || { from: "30", to: "Today" };
  const today = new Date();
  const lastXDays = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 30
  );

  const [dateInterval, _setDateInterval] = useState({
    from: from,
    to: to,
  });

  const setDateInterval = (interval: DateInterval) => {
    _setDateInterval({
      from: interval?.from || lastXDays.toLocaleDateString("en-US"),
      to: interval?.to || today.toLocaleDateString("en-US"),
    });
  };

  return {
    setDateInterval: setDateInterval,
    dateInterval: dateInterval,
  };
};

export default useDate;
