import { Box, Modal, SxProps } from "@mui/material";
import React, { useState } from "react";

const style = {
  position: "absolute" as "absolute",
  top: {
    xs: "100%",
    sm: "50%",
  },
  maxHeight: {
    xs: "calc(94vh - 48px)",
    sm: "initial",
  },
  left: "50%",
  transform: {
    xs: "translate(-50%, -100%)",
    sm: "translate(-50%, -50%)",
  },
  width: {
    xs: "100%",
    sm: "auto",
  },
  bgcolor: "#ffffff",
  border: "1px solid #E0E0E0",
  boxShadow: 24,
  borderTopLeftRadius: "25px",
  borderTopRightRadius: "25px",
  borderBottomLeftRadius: {
    xs: "0",
    sm: "24px",
  },
  borderBottomRightRadius: {
    xs: "0",
    sm: "24px",
  },
  p: 3,
};

interface Options {
  customCss: any;
}

interface P {
  style?: SxProps;
  onCloseCb?: any;
}

export const useModal = () => {
  const [open, setOpen] = useState<any | null>({
    c: null,
    style: null,
    onCloseCb: null,
  });

  const openModal = (children: JSX.Element, p?: P) => {
    setOpen({
      c: children,
      style: p?.style,
      onCloseCb: p?.onCloseCb,
    });
  };

  const refreshModal = () => {};

  const closeModal = () => {
    open.onCloseCb && open.onCloseCb();
    setOpen({
      c: null,
      style: null,
      onCloseCb: null,
    });
  };

  const renderModal = () => {
    return (
      <Modal
        open={open.c !== null}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          overflowY: "auto",
          "& .MuiBackdrop-root": {
            //    opacity: 0.5,
            backgroundColor: "#ffffff78",

            // backdropFilter: "blur(4px)",
          },
        }}
      >
        <Box
          sx={{
            ...style,
            ...open.style,
          }}
        >
          {open.c}
        </Box>
      </Modal>
    );
  };

  return {
    openModal,
    renderModal,
    closeModal,
    refreshModal,
    isOpened: open.c !== null,
  };
};
